import React from "react";
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import Layout from "../components/Layout/layout"
import { Sizes } from '../components/Layout/sizes';
import { Heading, Text } from '../components/Layout/pageParts';
import { Colours } from '../components/Layout/colours';

function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

export const Container = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: space-between;      
    width: 75%;

    @media screen and ${Sizes.mobileS} {
      flex-direction: row;            
    }
`;

const Col1 = styled.div`
  flex: 1;  
`;

const Col2 = styled.div`
  margin-left: 30px;
  flex: 1;
`;

const BoldText = styled.span`  
  font-weight: bolder;
`;

const FormContainer = styled.div`        
  padding-top:15px;
  padding-right:15px;
  border-radius: 8px;	        
`;

const FormInput = styled.input`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;     
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
  background: ${Colours.Focus};
  }
`;

const FormTextArea = styled.textarea`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  min-height:120px;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;         
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
      background: ${Colours.Focus};
  }
`;

const FormButton = styled.button`
  position: relative;
  display: block;
  padding: 19px 39px 18px 39px;
  color: #FFF;
  margin: 0 auto;
  background: ${Colours.Darkest};
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 100%;
  border: 1px solid ${Colours.Focus};;
  border-width: 1px 1px 3px;
  margin-bottom: 10px;

  :hover:enabled{
      background: ${Colours.Dark};
  }

  :disabled {
  opacity: 0.2;
  }
`;

const WorkshopPage = () => {

  const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "JoinUs.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

const [state, setState] = React.useState({})
    const [canSend, setCanSend] = React.useState(false);

    const handleChange = (e) => {

        var latestState = { ...state, [e.target.name]: e.target.value };
        setState(latestState);

        const validName = latestState['name'] !== undefined && latestState['name'] !== '';
        const validEmail = latestState['email'] !== undefined && latestState['email'] !== '';        
        setCanSend(validName && validEmail);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
    <Layout>
        <Container>
            <Col1>                
                <Img alt="photo" fluid={data.fileName.childImageSharp.fluid} style={{ width: "95%", height: "95%" }} imgStyle={{ objectPosition: 'left' }} />
            </Col1>
            <Col2>
            <Heading>Essential Oils Workshop</Heading>
            <Text>Online workshop for <BoldText>YOU</BoldText> to make empowered choices at this current time.</Text>            
            <Text>We will get clear and delve deeper into the wisdom of essential oils that support positive daily habits.</Text>  
               
            <Text>You will learn how to support your:</Text>            
              <ul>
                <li>Immune system</li>
                <li>Digestive system</li>
                <li>Sleep</li>
                <li>Stress</li>
                <li>Hormones</li>
                <li>Physical Pain / Sporting Injuries</li>
              </ul>
            <Text>Price: <BoldText>FREE!</BoldText></Text>  
            <Text>When: <BoldText>Saturday 21st November at 10.30am</BoldText></Text>                        
            <Text>Enter your details to register and I'll be in touch.</Text>          
            <FormContainer>
                <form name="workshop"
                    method="post"
                    action="/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}>
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="workshop" />
                    <p hidden>
                        <label>
                            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                        </label>
                    </p>
                    <FormInput type="text" name="name" placeholder="Your Name *" onChange={handleChange} />
                    <FormInput type="email" name="email" placeholder="Your Email *" onChange={handleChange} />
                    <FormTextArea name="message" placeholder="Any other information" onChange={handleChange}></FormTextArea>
                    <FormButton disabled={!canSend} type="submit">Register</FormButton>
                </form>
            </FormContainer>
            </Col2>
            </Container>
    </Layout>
    
)
};

export default WorkshopPage;
